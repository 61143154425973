import React, {FC} from 'react';
import {useNavigate} from "react-router-dom";
import {buildAppUrl} from "../Helpers/BuildAppUrl";

import {Button} from "antd";

import logo from "../Resources/logo.png";

interface HeaderProps {
    onFeaturesClick: () => void,
    onStepsClick: () => void,
    onFeedbackClick: () => void,
    onDownloadClick: () => void
}

const HeaderPanel: FC<HeaderProps> = (props) => {
    const classStyle =
        "text-center cursor-pointer hover:text-pink-400 text-zinc-100 text-sm md:text-xl font-regular --webkit-font-['Onest'] leading-normal";

    const navigator = useNavigate();

    return (
        <div className="bg-neutral-900 text-white w-full h-full">
            <div className="flex space-x-8 justify-center md:justify-end px-12 py-3">
                <p
                    className={classStyle}
                    onClick={() => {
                        props.onStepsClick()
                    }}
                >
                    Steps
                </p>
                <p
                    className={classStyle}
                    onClick={() => {
                        props.onFeedbackClick()
                    }}
                >
                    User feedback
                </p>
                <a
                    className={classStyle}
                    href={"https://deepfakex.ai/examples"}
                >
                    <p>
                        Examples
                    </p>
                </a>
                <div className="py-2 md:py-4">
                    <Button
                        type="primary"
                        className="transition ease-in-out delay-75 h-8 bg-pink-500 rounded-2xl hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                        onClick={() => window.open(buildAppUrl())}
                    >
                        Launch App
                    </Button>
                </div>
            </div>
            <div className="left-[48px] top-[8px] absolute justify-start items-center invisible md:visible">
                <img alt="logo" src={logo} className="w-16 h-16"/>
            </div>
        </div>
    );
};

export default HeaderPanel;