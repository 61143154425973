import React from 'react';

import heroImage from '../Resources/Hero Pic.png';
import {buildAppUrl} from "../Helpers/BuildAppUrl";

const HeroBlock = () => {
    return (
        <div
            className="flex flex-col-reverse lg:flex-row bg-neutral-900 justify-start items-center gap-16 px-8 xl:px-48 py-8 xl:py-16">
            <div className="flex flex-col justify-start items-start gap-8">
                <div className="self-stretch flex-col justify-start items-start gap-14 flex">
                    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
                        <h1 className="text-left lg:font-extrabold lg:text-5xl text-zinc-100">Deepfake video with
                            anybody in under one minute</h1>
                        <p className="text-left text-zinc-300 text-xl font-medium --webkit-font-['Onest']">Upload a
                            target video, upload just one photo, select a face and watch your deepfake created.</p>
                    </div>
                </div>
                <div className="self-stretch justify-left items-center gap-6 inline-flex">
                    <div
                        className="transition ease-in-out delay-75 grow shrink basis-0 h-14 p-2 bg-pink-500 rounded-2xl justify-center items-center gap-2.5 flex hover:cursor-pointer hover:-translate-y-1 hover:scale-105 duration-100"
                        onClick={() => window.open(buildAppUrl())}
                    >
                        <div
                            className="text-center text-zinc-100 text-xl font-bold --webkit-font-['Onest'] leading-normal"
                        >
                            Create Deepfake Video
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroBlock;