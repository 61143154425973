import React, {FC} from 'react';

import stepOne from '../Resources/IMG Area.webp';
import stepTwo from '../Resources/IMG Area-1.webp';
import stepThree from '../Resources/IMG Area-2.png';

interface StepProps {
    title: string,
    subtitle: string,
    number: string,
    image: string
}

const StepCard: FC<StepProps> = (props) => {
    return (
        <div className="flex flex-col gap-4 bg-neutral-900 rounded-3xl border-solid border-spacing-0.5 border-pink-500 p-4 text-left">
            <img alt="step" className="h-32" src={props.image}/>
            <div className="font-extrabold text-transparent text-5xl bg-clip-text bg-gradient-to-r from-pink-400 to-purple-500 --webkit-font-['Unbounded'] leading-10">{props.number}</div>
            <div className="text-zinc-100 text-xl font-bold --webkit-font-['Unbounded'] leading-10">{props.title}</div>
            <div className="text-zinc-500 text-lg font-normal --webkit-font-['Onest'] leading-normal">{props.subtitle}</div>
        </div>
    );
};

const StepsBlock = () => {
    return (
        <div className="flex flex-col bg-neutral-900 justify-start items-start gap-4 w-full px-8 xl:px-48 py-8 text-left">
            <div className="justify-start items-center">
                <h2 className="text-zinc-100 text-5xl font-bold --webkit-font-['Unbounded'] leading-10">3 simple steps: Deepfake App</h2>
            </div>
            <p className="text-zinc-500 text-2xl font-medium --webkit-font-['Onest'] leading-loose">Whether you want to create a quick deepfake online or go the extra mile with our advanced tools, our free deepfake video maker has got you covered.</p>
            <div className="flex flex-col lg:flex-row gap-2 justify-between w-full place-items-center">
                <StepCard number="1" title="Upload a video" subtitle="Select any video up to 2 mins" image={stepTwo}/>
                <StepCard number="2" title="Upload a photo" subtitle="Pick an image with desired face" image={stepOne}/>
                <StepCard number="3" title="Get the result!" subtitle="Enjoy video with face applied" image={stepThree}/>
            </div>
        </div>
    );
};

export default StepsBlock;